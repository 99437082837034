import React from "react";
import NeuButton from "./neuButton";
import NeuText from "./neuText";
import { formatParagraph } from "../lib/utils";

export default function TitleDescButton({
  title,
  subtitle,
  subtext,
  desc,
  button,
  textAlign = "text-center",
  items = "items-center",
  titleSize,
}) {
  return (
    <div
      className={`flex flex-col justify-center ${items} ${textAlign} relative`}
    >
      <div className="relative">
        <NeuText text={title} size={titleSize} />
      </div>
      {subtitle && (
        <h2
          className={`tracking-tighter text-4xl leading-none uppercase font-josefin mt-5`}
        >
          {subtitle}
        </h2>
      )}
      {subtext && (
        <p className="tracking-tighter text-3xl leading-none font-josefin font-normal mt-2">
          {subtext}
        </p>
      )}
      {desc && (
        <div className={`py-5`}>
          {formatParagraph(desc).map((i, index) => (
            <p
              key={index}
              className={`text-2xl leading-tight lg:text-3xl my-5 font-light`}
            >
              {i}
            </p>
          ))}
        </div>
      )}
      {button && (
        <NeuButton
          title={button.title}
          url={button.url}
          onClick={button.onClick}
        />
      )}
    </div>
  );
}
