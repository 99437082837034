import React from "react";
import imageSrc from "../../images/west-malaysia.png";
import AnimationContainer from "../animationContainer";

export default function WestMalaysia() {
  return (
    <div
      className="relative flex justify-center items-center"
      style={{ width: "100%" }}
    >
      <img src={imageSrc} alt="west malaysia map" />

      <AnimationContainer
        animate={"animate-breath-opacity"}
        className="aes-circle2 absolute w-3 md:w-5 lg:w-10 h-3 md:h-5 lg:h-10"
        style={{ top: "65%", left: "45%" }}
      />
      <AnimationContainer
        animate={"animate-breath-opacity"}
        className="aes-circle2 absolute w-2 md:w-4 lg:w-8 h-2 md:h-4 lg:h-8"
        style={{
          top: "25%",
          left: "30%",
          animationDelay: ".5s",
        }}
      />
      <AnimationContainer
        animate={"animate-breath-opacity"}
        className="aes-circle2 absolute w-2 md:w-4 lg:w-8 h-2 md:h-4 lg:h-8"
        style={{
          top: "85%",
          left: "65%",
          animationDelay: ".5s",
        }}
      />
    </div>
  );
}
