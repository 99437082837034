export default function Layout({ children, id, screen }) {
  return (
    <div
      id={id}
      className={`relative font-josefin flex flex-col justify-center items-center ${
        screen ? "min-h-screen" : ""
      }`}
      style={{
        padding: "5%",
        maxWidth: 1920,
      }}
    >
      {children}
    </div>
  );
}
