const storage = {
  get: function (name) {
    return sessionStorage.getItem(name);
  },
  set: function (name, data, expiresIn) {
    const expiresAt = Date.now() + expiresIn;
    sessionStorage.setItem(name, data);
    sessionStorage.setItem(`${name}-expiresAt`, expiresAt);
  },
  isExpired: function (name) {
    const expiresAt = sessionStorage.getItem(`${name}-expiresAt`);
    if (expiresAt) {
      return expiresAt < Date.now();
    } else {
      return true;
    }
  },
};

export default storage;
