import Layout from "../layout";
import TitleDescButton from "../titleDescButton";
import SpinningShape from "../animated/spinningShape";
import { formatParagraph } from "../../lib/utils";

export default function AboutUs({ data }) {
  return (
    <>
      <Layout id={data.slug}>
        <SpinningShape />
        <div className="grid grid-cols-6 gap-10 relative py-10">
          <div className="col-span-6 lg:col-span-4 lg:col-start-2 flex justify-center items-center">
            <TitleDescButton
              title={data.neumorphic_text}
              desc={data.subtitle}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 gap-10 relative mt-10">
          <div className="col-span-6 lg:col-span-2 lg:col-start-2 text-center">
            <h2
              className="tracking-wider uppercase font-josefin z-20"
              style={{ fontSize: "4vmax" }}
            >
              {"Vision"}
            </h2>
            <div className={``}>
              {formatParagraph(data.vision).map((i, index) => (
                <p
                  key={index}
                  className={`text-2xl leading-tight lg:text-3xl my-5 font-light`}
                >
                  {i}
                </p>
              ))}
            </div>
          </div>
          <div className="col-span-6 lg:col-span-2 text-center">
            <h2
              className="tracking-wider uppercase font-josefin z-20"
              style={{ fontSize: "4vmax" }}
            >
              {"Mission"}
            </h2>
            <div className={``}>
              {formatParagraph(data.mission).map((i, index) => (
                <p
                  key={index}
                  className={`text-2xl leading-tight lg:text-3xl my-5 font-light`}
                >
                  {i}
                </p>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
