import { useEffect, useState } from "react";
import Main from "../components/sections/main";
import AboutUs from "../components/sections/aboutUs";
import {
  getAllCategoriesFromServer,
  getCategoryFromServer,
} from "../lib/utils";
import SalesAndMarketing from "../components/sections/salesAndMarketing";
import OurLeadership from "../components/sections/ourLeadership";
import YourFuture from "../components/sections/yourFuture";
import Upcoming from "../components/sections/upcoming";
import Training from "../components/sections/training";
import SectorsServed from "../components/sections/sectorsServed";
import MovingBoxes from "../components/animated/movingBoxes";
import Engagements from "../components/sections/engagements";
import storage from "../lib/storage";

const DATA_ID = "ba_home";

export default function Home() {
  const [sections, setSections] = useState({});
  const [events, setEvents] = useState([]);
  const [engagements, setEngagements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      if (storage.isExpired(DATA_ID)) {
        const categories = await getAllCategoriesFromServer();
        let categoryIds = {};
        for (let category of categories) {
          categoryIds[category.slug] = category.id;
        }
        const sectionsData = await getCategoryFromServer(categoryIds.sections);
        const eventsData = await getCategoryFromServer(categoryIds.events);
        const engagementsData = await getCategoryFromServer(
          categoryIds.engagements
        );
        const sections = {};
        for (let section of sectionsData) {
          sections[section.slug] = { ...section.acf, slug: section.slug };
        }
        const events = eventsData.map((event) => event.acf);
        const engagements = engagementsData.map((engagement) => engagement.acf);
        setSections(sections);
        setEvents(events);
        setEngagements(engagements);
        setLoading(false);
        storage.set(
          DATA_ID,
          JSON.stringify({ sections, events, engagements }),
          60 * 60 * 1000 // 1 hour
        );
      } else {
        const { sections, events, engagements } = JSON.parse(
          storage.get(DATA_ID)
        );
        setSections(sections);
        setEvents(events);
        setEngagements(engagements);
        setLoading(false);
      }
    };
    initialize();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-standard">
        <MovingBoxes />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <Main data={sections.home} />
      <AboutUs data={sections["about-us"]} />
      <OurLeadership data={sections["our-leadership"]} />
      <Training data={sections["training"]} />
      <SalesAndMarketing data={sections["sales-and-marketing"]} />
      <SectorsServed data={sections["sectors-served"]} />
      {engagements.length > 0 && <Engagements data={engagements} />}
      {events.length > 0 && <Upcoming data={events} />}
      <YourFuture data={sections["your-future"]} />
    </>
  );
}
