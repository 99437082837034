import Layout from "../layout";
import ModalButton from "../modal";
import TitleDescButton from "../titleDescButton";

export default function OurLeadership({ data }) {
  return (
    <>
      <Layout id={data.slug}>
        <div className="grid grid-cols-6 gap-10 relative py-10">
          <div className="col-span-6 lg:col-span-3 flex justify-center items-center">
            <img
              src={data.leader_1_image.url}
              alt={data.leader_1_name}
              className="object-contain"
              style={{ width: "70%" }}
            />
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col justify-center items-center lg:items-start">
            <TitleDescButton
              title={data.neumorphic_text}
              subtitle={data.leader_1_name}
              subtext={data.leader_1_title}
              desc={data.leader_1_short_bio}
              textAlign="text-center lg:text-left"
              items="items-center lg:items-start"
              titleSize="small"
            />
            <ModalButton
              buttonText="Learn More"
              modalTitle={data.leader_1_name}
              modalBody={data.leader_1_full_bio}
            />
          </div>
        </div>
      </Layout>
      <Layout>
        <div className="grid grid-cols-6 gap-10 relative py-10">
          <div className="col-span-6 lg:col-span-3 flex flex-col justify-center items-center lg:items-end order-2 lg:order-1">
            <TitleDescButton
              title={data.neumorphic_text}
              subtitle={data.leader_2_name}
              subtext={data.leader_2_title}
              desc={data.leader_2_short_bio}
              textAlign="text-center lg:text-right"
              items="items-center lg:items-end"
              titleSize="small"
            />
            <ModalButton
              buttonText="Learn More"
              modalTitle={data.leader_2_name}
              modalBody={data.leader_2_full_bio}
            />
          </div>
          <div className="col-span-6 lg:col-span-3 flex justify-center items-center order-1 lg:order-2">
            <img
              src={data.leader_2_image.url}
              alt={data.leader_2_name}
              className="object-contain"
              style={{ width: "70%" }}
            />
          </div>
        </div>
      </Layout>
    </>
  );
}
