import React from "react";
import { useInView } from "react-intersection-observer";

export default function NeuText({ text, size }) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const textSizing = () => {
    switch (size) {
      case "big":
        return "lg:text-9xl text-7xl";
      case "small":
        return "lg:text-7xl md:text-6xl sm:text-5xl text-5xl";
      case "extra-small":
        return "lg:text-7xl md:text-6xl sm:text-5xl text-4xl";
      default:
        return "lg:text-8xl md:text-6xl sm:text-5xl text-5xl";
    }
  };
  return (
    <h1
      ref={ref}
      className={`nm-text no-cpu inline-block font-extrabold leading-none font-spartan ${textSizing()} m-0 ${
        inView ? "animate-neu-text" : ""
      }`}
    >
      {text}
    </h1>
  );
}
