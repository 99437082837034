import TitleDescButton from "../titleDescButton";
import Layout from "../layout";
import { Fragment, useEffect, useState } from "react";
import NeuPseudo from "../neuPseudo";

export default function SectorsServed({ data }) {
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    const icons = [];
    const predicate = "sector_icon_";
    for (let [key, value] of Object.entries(data)) {
      if (key.includes(predicate)) {
        icons.push(value);
      }
    }
    setIcons(icons);
  }, [data]);
  return (
    <Layout id={data.slug}>
      <div className="grid grid-cols-6 gap-5 relative py-10">
        <div
          className="col-span-6 flex justify-center items-center relative"
          style={{ padding: "0 10%" }}
        >
          <TitleDescButton title={data.neumorphic_text} desc={data.subtitle} />
        </div>
        <div className="col-span-6">
          <div className="flex justify-center items-center flex-wrap relative">
            {icons.map((icon, index) => (
              <Fragment key={index}>
                <div className="flex flex-col justify-center items-center">
                  <div className="w-32 h-32 p-5 transform duration-200 hover:scale-110">
                    <NeuPseudo
                      style={{
                        animationDuration: `${1 + index * 0.2}s`,
                      }}
                    >
                      <img src={icon.url} alt="" className="nm-light" />
                    </NeuPseudo>
                  </div>
                  <p className="text-xs">{icon.title}</p>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
