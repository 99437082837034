import React from "react";
import * as animationData from "../../images/lottie/spinning-shapes.json";
import { Lottie } from "../lottie";

export default function SpinningShape(props) {
  return (
    <Lottie
      id={"spinning-shape"}
      className={`absolute opacity-25 h-screen w-screen`}
      animationData={animationData}
    />
  );
}
