import axios from "axios";
import {
  POSTS_API_URL,
  CATEGORIES_API_URL,
  CONTACT_FORM_API_URL,
  MENU_API_URL,
} from "./constants";

export const getMenuFromServer = async () => {
  try {
    const { data } = await axios.get(`${MENU_API_URL}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAgentFromServer = async (agentId) => {
  try {
    const { data } = await axios.get(
      `${POSTS_API_URL}?slug=${agentId}&_filter=acf,slug`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryFromServer = async (categoryId, limit = 10) => {
  try {
    const { data } = await axios.get(
      `${POSTS_API_URL}?categories=${categoryId}&per_page=${limit}&_filter=slug,id`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCategoriesFromServer = async () => {
  try {
    const { data } = await axios.get(`${CATEGORIES_API_URL}?&_filter=acf,slug`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const postContactForm = async (jsonBody) => {
  try {
    const formBody = new FormData();
    for (let key in jsonBody) {
      formBody.append(key, jsonBody[key]);
    }
    const { data } = await axios.post(`${CONTACT_FORM_API_URL}`, formBody);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const formatString = (text) => {
  text = text
    .split("_")
    .map((i) => i.capitalCase())
    .join("");

  text = text
    .split("-")
    .map((i) => i.capitalCase())
    .join("");
  return text;
};

export const capitalCase = (text) => {
  text = text.split("", 1);
  return text[0].toUpperCase() + text[1];
};

export const formatParagraph = (paragraph) => {
  return paragraph.split(/\r\n\r/g);
};

export const findPos = (obj) => {
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [curtop];
  }
};

export const myLoader = ({ src }) => {
  return src;
};
export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}
