import Layout from "../layout";
import Slider from "react-slick";
import NeuText from "../neuText";
import { useEffect, useState } from "react";

export default function Upcoming({ data = [] }) {
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    let slidesToShow = 3;
    let responsive = [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ];
    if (data.length > 0) {
      if (data.length < 3) {
        slidesToShow = data.length;
        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow,
          slidesToScroll: 1,
        });
      } else {
        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow,
          slidesToScroll: 1,
          responsive,
        });
      }
    }
  }, [data.length]);
  return (
    <Layout id="upcoming">
      <div className="text-center mb-5 lg:mb-20 pt-32 sm:pt-0">
        <NeuText text={"Upcoming Programs"} />
      </div>
      <div className="w-full px-5">
        {settings && (
          <Slider {...settings}>
            {data.map((event, index) => (
              <div key={index}>
                <div className="flex flex-col justify-center items-center w-full p-5">
                  <img
                    src={event.image.url}
                    alt=""
                    className="object-contain"
                  />
                  <a
                    href={event.link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md lg:text-2xl mt-5 hover:opacity-75"
                  >
                    {event.link.title}
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </Layout>
  );
}
