import React from "react";
import { useInView } from "react-intersection-observer";

export default function AnimationContainer({
  children,
  className = "",
  style = {},
  animate = "",
}) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div
      ref={ref}
      style={style}
      className={`${inView ? animate : ""} ${className}`}
    >
      {children}
    </div>
  );
}
