import {
  LocationMarkerIcon,
  MapIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import MovingBoxes from "../components/animated/movingBoxes";
import Layout from "../components/layout";
import { getAgentFromServer } from "../lib/utils";

export default function SalesPartners(props) {
  const [loading, setLoading] = useState(true);
  const [agent, setAgent] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const checkId = async () => {
      if (id) {
        const data = await getAgentFromServer(id.toLowerCase());
        if (data.length > 0 && data[0].acf) {
          setAgent(data[0].acf);
        } else {
          setAgent(null);
        }
      }
      setLoading(false);
    };
    checkId();
  }, [id]);

  if (loading) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-standard">
        <MovingBoxes />
        <p>Loading...</p>
      </div>
    );
  }

  if (!agent) {
    return <Redirect to="/not-found" />;
  }
  return (
    <Layout id="sales-partners" screen>
      <div className="grid grid-cols-6 gap-4 relative py-24 xl:py-0 xl:pt-16 xl:pb-6">
        <div className="col-span-6 text-center flex flex-col lg:flex-row justify-between items-center lg:items-end">
          <img
            src={agent.header_logo.url}
            alt=""
            className="h-16 object-cover"
          />
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-700 mt-4 lg:mt-0 leading-none">
            Sales Partner Verification
          </h1>
        </div>
        <div className="col-span-6 flex flex-col items-center justify-center border border-gray-100 rounded-lg nm-box-set px-4 py-8 md:px-8 md:py-6">
          <div className="flex flex-col-reverse lg:flex-row pb-5">
            <div className="py-4 lg:py-0 lg:pr-4">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-700 mb-4">
                ID: {agent.agent_id}
              </h1>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1">
                  <label className="text-xl flex justify-start items-center">
                    <UserIcon className="w-4 h-4 mr-1 mb-1" />
                    Name
                  </label>
                  <p className="text-gray-600 text-lg nm-box-inset pt-4 pl-4 pr-2 pb-2 rounded-md lg:w-80 ">
                    {agent.name}
                  </p>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <label className="text-xl flex justify-start items-center">
                    <PhoneIcon className="w-4 h-4 mr-1 mb-1" />
                    Phone
                  </label>
                  <p className="text-gray-600 text-lg nm-box-inset pt-4 pl-4 pr-2 pb-2 rounded-md lg:w-80 ">
                    {agent.phone}
                  </p>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <label className="text-xl flex justify-start items-center">
                    <LocationMarkerIcon className="w-4 h-4 mr-1 mb-1" />
                    Address
                  </label>
                  <p className="text-gray-600 text-lg nm-box-inset pt-4 pl-4 pr-2 pb-2 rounded-md lg:w-80 ">
                    {agent.address}
                  </p>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <label className="text-xl flex justify-start items-center">
                    <MapIcon className="w-4 h-4 mr-1 mb-1" />
                    Region
                  </label>
                  <p className="text-gray-600 text-lg nm-box-inset pt-4 pl-4 pr-2 pb-2 rounded-md lg:w-80 ">
                    {agent.region}
                  </p>
                </div>
              </div>
            </div>
            <div className="pb-6 md:pb-0 flex justify-center items-center md:items-start">
              <img
                src={agent.photo.url}
                alt="sales partner profile"
                className="w-48 h-48 object-cover border-2 border-gray-100 p-2 nm-box-set rounded-lg"
              />
            </div>
          </div>
          <div className="pt-4 border-t-2 border-gray-300 w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <h1 className="text-lg font-semibold text-gray-400 uppercase leading-4">
              {agent.bottom_text}
            </h1>
          </div>
        </div>
        <div className="col-span-6 text-center mt-5">
          <Link to="/" className="nm-box-set px-5 py-2 rounded-lg">
            Go Back Home
          </Link>
        </div>
      </div>
    </Layout>
  );
}
