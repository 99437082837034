import React from "react";
import { useInView } from "react-intersection-observer";

export default function NeuPseudo({ children, className = "", style = {} }) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div
      ref={ref}
      style={style}
      className={`neu-pseudo ${
        inView ? "animate-neu-pseudo" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
}
