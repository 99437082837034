import { useEffect, useState } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import { useInView } from "react-intersection-observer";

export function Lottie({ id, animationData, className }) {
  const [animation, setAnimation] = useState(false);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.querySelector(`.${id}`),
      animationData: animationData.default,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: false, // boolean
      useSubFrames: false,
    });
    setAnimation(animation);
  }, [id, animationData, className]);

  useEffect(() => {
    if (animation) {
      if (inView) {
        animation.play();
      } else {
        animation.stop();
      }
    }
  }, [inView, animation]);

  return <div ref={ref} className={`${id} ${className}`} />;
}
