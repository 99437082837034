import React from "react";
import * as animationData from "../../images/lottie/moving-boxes.json";
import { Lottie } from "../lottie";

export default function MovingBoxes(props) {
  return (
    <Lottie
      id="moving-boxes"
      className={`flex justify-center items-center opacity-50`}
      animationData={animationData}
    />
  );
}
