import TitleDescButton from "../titleDescButton";
import Layout from "../layout";
import { useEffect, useState } from "react";
import NeuPseudo from "../neuPseudo";

export default function Training({ data }) {
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    const icons = [];
    const predicate = "training_icon_";
    for (let [key, value] of Object.entries(data)) {
      if (key.includes(predicate)) {
        icons.push(value);
      }
    }
    setIcons(icons);
  }, [data]);
  return (
    <Layout id={data.slug} screen>
      <div className="grid grid-cols-6 gap-10 relative">
        <div className="col-span-6 xl:col-span-2 order-2 xl:order-1 flex items-center justify-center">
          <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-2 gap-6 md:gap-10 relative">
            {icons.map((icon, index) => (
              <div
                key={index}
                className="nm-box-set w-32 h-32 lg:w-40 xl:w-52 lg:h-40 xl:h-52 p-2 xl:p-5 rounded-3xl col-span-1 transform duration-200 hover:scale-110"
              >
                <NeuPseudo
                  style={{
                    animationDuration: `${1 + index * 0.2}s`,
                  }}
                >
                  <img src={icon.url} alt="" className="nm-light" />
                </NeuPseudo>
              </div>
            ))}
          </div>
        </div>
        <div
          className="col-span-6 xl:col-span-4 flex justify-center items-center order-1 xl:order-2"
          style={{ padding: "0 10%" }}
        >
          <TitleDescButton
            title={data.neumorphic_text}
            desc={data.subtitle}
            button={data.button}
          />
        </div>
      </div>
    </Layout>
  );
}
