import { useRef, useState } from "react";
import Layout from "../layout";
import TitleDescButton from "../titleDescButton";
import EastMalaysia from "../icons/eastMalaysia";
import WestMalaysia from "../icons/westMalaysia";
import NeuPseudo from "../neuPseudo";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CheckCircleIcon, CogIcon } from "@heroicons/react/outline";
import { postContactForm } from "../../lib/utils";

export default function YourFuture({ data }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    "full-name": "",
    email: "",
    message: "",
  });
  const inputHandler = (type, value) => {
    setFormData({ ...formData, [type]: value });
  };

  const captchaRef = useRef(null);
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      formData.email === "" ||
      formData.message === "" ||
      formData["full-name"] === ""
    ) {
      alert("Please fill in all information.");
      return;
    }
    setLoading(true);
    captchaRef.current.execute();
  };
  const onExpire = () => {
    console.log("hCaptcha Token Expired");
  };

  const onError = (err) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  const onVerify = async (token) => {
    if (token) {
      const response = await postContactForm({ ...formData, hcaptcha: token });
      if (response) {
        alert(response.message);
      }
      captchaRef.current.resetCaptcha();
      setLoading(false);
      setFormData({
        "full-name": "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <Layout id={data.slug}>
      <div className="grid grid-cols-6 gap-10 py-10">
        <div className="col-span-6 lg:col-span-6 relative lg:px-20">
          <NeuPseudo className="w-full flex justify-between items-center lg:absolute inset-0">
            <WestMalaysia />
            <EastMalaysia />
          </NeuPseudo>
          <div className="flex flex-col justify-center items-center relative">
            <div className="z-10">
              <TitleDescButton
                title={
                  <>
                    {data.neumorphic_text}
                    <br />
                    {data.title}
                  </>
                }
                desc={data.subtitle}
              />
            </div>
          </div>
        </div>
        <div className="col-span-6 lg:col-span-6 flex justify-center items-center lg:m-10">
          <div className="w-full lg:w-1/2">
            <form onSubmit={onSubmit}>
              <div className="nm-box-set text-black rounded-lg px-4 py-10 space-y-6 sm:p-6">
                <div>
                  <label
                    htmlFor="full-name"
                    className="block text-lg font-medium text-black"
                  >
                    Your Name
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      className="w-full pl-4 pr-2 pt-4 pb-2 text-lg rounded-md text-gray-800 nm-box-inset"
                      placeholder="Mr. John Doe"
                      value={formData["full-name"]}
                      onChange={(e) =>
                        inputHandler("full-name", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-lg font-medium text-black"
                  >
                    Email
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full pl-4 pr-2 pt-4 pb-2 text-lg rounded-md text-gray-800 nm-box-inset"
                      placeholder="youremail@website.com"
                      value={formData.email}
                      onChange={(e) => inputHandler("email", e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block text-lg font-medium text-black"
                  >
                    Your Message
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <textarea
                      id="message"
                      name="message"
                      rows={8}
                      className="w-full pl-4 pr-2 pt-4 pb-2 text-lg rounded-md text-gray-800 nm-box-inset"
                      placeholder="Your message here..."
                      value={formData.message}
                      onChange={(e) => inputHandler("message", e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    className="text-md hover:opacity-75 nm-box-set rounded-full px-10 py-2 flex uppercase w-50 justify-center items-center"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CogIcon className="animate-spin h-5 w-5" />
                    ) : (
                      <>
                        Submit
                        <CheckCircleIcon className="h-5 w-5 ml-2" />
                      </>
                    )}
                  </button>
                  <HCaptcha
                    sitekey="a2b1431e-6b7f-41eb-aac2-9e1f6a6b4001"
                    size="invisible"
                    onVerify={onVerify}
                    onError={onError}
                    onExpire={onExpire}
                    ref={captchaRef}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
