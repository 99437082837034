import React from "react";
import * as animationData from "../../images/lottie/breathing.json";
import { Lottie } from "../lottie";

export default function BreathingShape(props) {
  return (
    <Lottie
      id="breathing-shape"
      className={`absolute w-screen opacity-25`}
      animationData={animationData}
    />
  );
}
