import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/home";
import NoMatch from "./pages/noMatch";
import SalesPartners from "./pages/salesPartners";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Navigator from "./components/navigator";
import { getMenuFromServer } from "./lib/utils";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    const initialize = async () => {
      try {
        const menus = await getMenuFromServer();
        setMenus(
          menus.map((menu) => {
            return { name: menu.title, url: menu.url };
          })
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    initialize();
  }, []);
  return (
    <div className="relative">
      <nav>
        <Navbar loading={loading} menus={menus} />
      </nav>
      <main id="main-container">
        <Router>
          <Switch>
            <Route exact path={"/sales-partners/:id"}>
              <SalesPartners />
            </Route>
            <Route exact path="/">
              <Home />
              <Navigator loading={loading} menus={menus} />
            </Route>
            <Route exact path="/not-found">
              <NoMatch />
            </Route>
            <Route path="*">
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </Router>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
