import React from "react";

export default function Footer(props) {
  return (
    <div className="text-gray-300 font-josefin text-xs sm:text-base bg-gray-600">
      <div className="flex justify-center items-center px-4 pt-10">
        <h5 className="font-bold">Bukti Aman</h5>
        <p className="ml-2">•</p>
        <p className="ml-2">
          Wisma Lotus Lot 1A, Jalan 51A/223A, Off Jalan Utara, 46100 Petaling
          Jaya, Selangor Malaysia
        </p>
      </div>
      <div className="flex justify-center items-center px-4 pb-6 pt-4">
        <h5>Contact:</h5>
        <a className="ml-2 hover:opacity-75" href="tel:+603 9054 6060">
          +603 9054 6060
        </a>
        <p className="mx-2">|</p>
        <a className="hover:opacity-75" href="mailto:info@buktiaman.com">
          info@buktiaman.com
        </a>
      </div>
      <div className="flex justify-center items-center p-4 bg-gray-800">
        <p className="ml-2">
          © {new Date().getFullYear()} Bukti Aman Sdn. Bhd. All rights reserved
        </p>
      </div>
    </div>
  );
}
