import React from "react";
import { useInView } from "react-intersection-observer";

export default function NeuButton({
  title,
  url,
  type = "link",
  onClick,
  disabled,
}) {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  if (type === "button") {
    return (
      <button
        ref={ref}
        className={`nm-box px-10 py-2 text-lg lg:py-2 font-bold w-max rounded-full uppercase hover:px-20 tracking-wide cursor-pointer ${
          inView ? "animate-neu-box" : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {title}
      </button>
    );
  }
  return (
    <a
      ref={ref}
      className={`nm-box px-10 py-2 text-lg lg:py-2 font-bold w-max rounded-full uppercase hover:px-20 tracking-wide ${
        inView ? "animate-neu-box" : ""
      }`}
      href={url}
    >
      {title}
    </a>
  );
}
