import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";

const white = {
  container: "bg-gray-800 hover:bg-transparent",
  icon: "text-gray-standard group-hover:text-gray-800",
};

export default function Navigator({ loading, menus }) {
  const [topLink, setTopLink] = useState("#home");
  const [bottomLink, setBottomLink] = useState("#about-us");
  const [iMenus, setIMenus] = useState([]);

  useEffect(() => {
    const sectionHandler = ({ path }) => {
      const hash = path[0].location.hash;
      const index = iMenus.indexOf(hash);
      setTopLink(iMenus[index - 1] || iMenus[index]);
      setBottomLink(iMenus[index + 1] || iMenus[index]);
    };
    window.addEventListener("hashchange", sectionHandler, false);
    return () => {
      window.removeEventListener("hashchange", sectionHandler, false);
    };
  }, [iMenus]);

  useEffect(() => {
    if (!loading) {
      const iMenus = ["#home", ...menus.map((menu) => menu.url.split("/")[1])];
      setIMenus(iMenus);
      setTopLink(iMenus[0]);
      setBottomLink(iMenus[1]);
    }
  }, [loading, menus]);

  return (
    <div className="fixed bottom-10 z-40" style={{ left: "2.5rem" }}>
      {loading ? (
        <CogIcon className="h-12 w-12 animate-spin" />
      ) : (
        <>
          <a href={topLink}>
            <div
              className={`w-8 lg:w-14 h-8 lg:h-14 mb-5 flex justify-center items-center rounded-full cursor-pointer transition-all duration-200 group ${white.container}`}
            >
              <ChevronUpIcon
                className={`h-7 lg:w-10 w-7 lg:h-10 transition-all duration-200 ${white.icon}`}
              />
            </div>
          </a>
          <a href={bottomLink}>
            <div
              className={`w-8 lg:w-14 h-8 lg:h-14 flex justify-center items-center rounded-full cursor-pointer transition-all duration-200 group ${white.container}`}
            >
              <ChevronDownIcon
                className={`h-7 lg:w-10 w-7 lg:h-10 transition-all duration-200 ${white.icon}`}
              />
            </div>
          </a>
        </>
      )}
    </div>
  );
}
