import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CogIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

import logoBlack from "../images/logo-black.png";
const textWhite = "text-gray-800";

export default function Navbar({ loading, menus }) {
  const [open, setOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > containerRef.current.clientHeight) {
        setMobileNav(true);
      } else {
        setMobileNav(false);
      }
    };
  }, []);

  return (
    <>
      <div ref={containerRef} className="w-full fixed top-0 left-0 z-40">
        <div className="grid grid-cols-4 pt-4">
          <div className="col-span-1 pl-10">
            <div className="flex justify-start items-center h-full">
              <a href="/#home">
                <img
                  src={logoBlack}
                  alt="Bukti Aman logo"
                  className="w-8 lg:w-14"
                />
              </a>
            </div>
          </div>
          <div className="col-span-3 pr-10">
            <div className="flex justify-end items-center h-full">
              {loading ? (
                <CogIcon className="h-12 w-12 animate-spin" />
              ) : (
                <>
                  <div
                    className={`hidden justify-end items-center ${
                      mobileNav ? "lg:hidden" : "lg:flex"
                    } p-2`}
                  >
                    {menus.map((link, index) => (
                      <NavLink
                        key={index}
                        textColor={textWhite}
                        name={link.name}
                        url={link.url}
                      />
                    ))}
                  </div>
                  <div
                    className={`flex justify-end items-center h-full ${
                      mobileNav ? "lg:flex" : "lg:hidden"
                    }`}
                  >
                    <button
                      type="button"
                      className="-mr-1 flex p-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-0 sm:-mr-2 transition-colors duration-200"
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Dismiss</span>
                      <MenuIcon
                        className="h-12 w-12 text-gray-800"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <MobileMenu open={open} setOpen={setOpen} menus={menus} />
    </>
  );
}

function NavLink({ name, url, textColor, onClick }) {
  return (
    <a
      href={url}
      className={`mx-4 text-lg font-semibold ${textColor} hover:opacity-75 transition-all duration-200`}
      onClick={onClick}
    >
      {name}
    </a>
  );
}

function MobileMenu({ open, setOpen, menus }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center">
                      <a href="/#home" onClick={() => setOpen(false)}>
                        <img
                          src={logoBlack}
                          alt="Bukti Aman logo"
                          width={60}
                          height={80}
                        />
                      </a>
                      <div className="ml-3 h-7 flex items-center justify-center">
                        <button
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-12 w-12" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul className="my-6 divide-y divide-gray-200">
                          {menus.map((link, index) => (
                            <li key={index} className="py-3 flex">
                              <NavLink
                                textColor={textWhite}
                                name={link.name}
                                url={link.url}
                                onClick={() => setOpen(false)}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
