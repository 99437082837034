import Layout from "../layout";
import Slider from "react-slick";
import NeuText from "../neuText";
import { useEffect, useState } from "react";
import { formatParagraph } from "../../lib/utils";
import { ExternalLinkIcon } from "@heroicons/react/outline";

export default function Engagements({ data = [] }) {
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    let slidesToShow = 3;
    let responsive = [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ];
    if (data.length > 0) {
      if (data.length === 2) {
        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        });
      } else if (data.length === 1) {
        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else {
        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow,
          slidesToScroll: 1,
          responsive,
        });
      }
    }
  }, [data.length]);
  return (
    <Layout id="engagements">
      <div className="text-center mb-5 lg:mb-20">
        <NeuText text={"Our Projects"} />
      </div>
      <div className="w-full px-5">
        {settings && (
          <Slider {...settings}>
            {data.map((engagement, index) => (
              <div key={index}>
                <div className="w-full p-5 flex flex-col justify-center items-center">
                  <a
                    className="p-5 rounded-md hover:opacity-75"
                    href={engagement.link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={engagement.logo.url}
                      alt=""
                      className="object-contain h-40"
                    />
                  </a>

                  <div className={`py-5 text-center`}>
                    {formatParagraph(engagement.description).map((i, index) => (
                      <p
                        key={index}
                        className={`text-2xl leading-tight lg:text-3xl my-5 font-light`}
                      >
                        {i}
                      </p>
                    ))}
                  </div>
                  <a
                    href={engagement.link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md hover:opacity-75 nm-box-set rounded-full px-10 py-2 flex"
                  >
                    {engagement.link.title}
                    <ExternalLinkIcon className="h-5 w-5 ml-2" />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </Layout>
  );
}
