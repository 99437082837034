import Layout from "../layout";
import NeuText from "../neuText";
import logoHeader from "../../images/logo-header.png";
import BreathingShape from "../animated/breathingShape";

export default function Main({ data }) {
  return (
    <Layout id={data.slug} screen>
      <div className="grid grid-cols-6 gap-10">
        <div className="col-span-6 lg:col-span-2 flex justify-center items-center relative">
          <BreathingShape />
          <img src={logoHeader} alt="" className="z-20 h-24 lg:h-full" />
        </div>
        <div className="col-span-6 lg:col-span-4 flex flex-col justify-center items-center relative">
          <div className="text-center z-10 relative">
            <NeuText text={data.neumorphic_text} size="big" />
            <h2 className="text-center text-4xl sm:text-5xl tracking-wider uppercase font-josefin z-20">
              {data.title}
            </h2>
          </div>
          <p
            className="text-lg sm:text-xl md:text-2xl lg:text-subtitle text-center z-20 mt-5"
            style={{ padding: "0 10%", lineHeight: 1.2 }}
          >
            {data.subtitle}
          </p>
        </div>
      </div>
    </Layout>
  );
}
