import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout";
import NeuText from "../components/neuText";

export default function NoMatch(props) {
  return (
    <Layout screen>
      <div className="flex flex-col justify-center items-center">
        <NeuText text="404" size="big" />
        <h2 className="text-center text-4xl sm:text-5xl tracking-wider uppercase font-josefin mb-20">
          Page Not Found
        </h2>
        <Link to="/" className="nm-box-set px-5 py-2 rounded-lg">
          Go Back Home
        </Link>
      </div>
    </Layout>
  );
}
